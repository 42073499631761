import { FC, useEffect, useState } from "react";
const { yupResolver } = require("@hookform/resolvers/yup"); //this is a temporary fix
import * as yup from "yup";
import { useForm } from "react-hook-form";
import classes from "./NewsLetterSubscribe.module.scss";
import styles from "..//..//@pages/FaqPage/FaqPage.module.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useScrollLock } from "../../hooks/useScroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import HubspotForm from "react-hubspot-form";

interface INewsLetterInputs {
	email: string;
	u: string;
	f: string;
	s: string;
	c: string;
	m: string;
	act: string;
	v: string;
	or: string;
}

const NewsLetterSubscribe: FC = () => {
	const [loading, setLoading] = useState(false);

	const newsLetterFormSchema = yup.object().shape({
		email: yup.string().required().email(),
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({});

	const onNewletterHandler = async (data: INewsLetterInputs, event: any) => {
		event.preventDefault();

		let myForm: any = document.getElementById(
			"hsForm_05ac9d85-af22-4d0f-b3ad-c2437fd4a0f2",
		);

		let value: any = document.getElementById(
			"email-05ac9d85-af22-4d0f-b3ad-c2437fd4a0f2",
		);
		let submitButton = myForm.querySelector("#main-submit");

		const { email, u, f, s, c, m, act, v, or } = data;

		const item: any = { email, u, f, s, c, m, act, v, or };
		var form_data = new FormData();

		for (var key in item) {
			form_data.append(key, item[key]);
		}

		if (item.email) {
			value.value = item.email;
			myForm.requestSubmit(submitButton);
		}

		fetch("https://buylowwarehouse.activehosted.com/proc.php", {
			method: "POST",
			mode: "no-cors",
			body: form_data,
		})
			.then((result) => {
				if (item.email) {
					setLoading(true);
				}
			})
			.catch((error) => {
				console.log(error, "error");
				setLoading(false);
			});

		// Reset form fields
		reset();
	};

	const hubspotFormFooter = () => {
		return (
			<div className={classes.nubSpotForm}>
				<HubspotForm
					portalId="23191308"
					formId="05ac9d85-af22-4d0f-b3ad-c2437fd4a0f2"
					onSubmit={() => console.log("footer form sent")}
					onReady={(form: any) => console.log("footer form ready!")}
					loading={
						<div className={styles.containerMainLoading}>Loading...</div>
					}
				/>
			</div>
		);
	};

	return (
		<section className={classes["section-ten"]}>
			<div className="container">
				<div className={classes["image-holder"]}>
					<img src="/img/section-10-logo-image.png" alt="image" />
				</div>

				<div className={classes["text-holder"]}>
					<p className={classes["text"]}>
						Join over 20,000 people to receive the newest <br /> list of
						products, access to exclusive deals + more
					</p>
				</div>
				{hubspotFormFooter()}

				<form onSubmit={handleSubmit(onNewletterHandler)}>
					<input type="hidden" {...register("u")} value="12" />
					<input type="hidden" {...register("f")} name="f" value="12" />
					<input type="hidden" {...register("s")} />
					<input type="hidden" {...register("c")} value="0" />
					<input type="hidden" {...register("m")} value="0" />
					<input type="hidden" {...register("act")} value="sub" />
					<input type="hidden" {...register("v")} value="2" />
					<input
						type="hidden"
						{...register("or")}
						value="420853ae26037f10326528228488e587"
					/>
					<div className={classes.inputHubSpot}>
						<div className={classes["subscribe-holder"]}>
							<div className={`${classes["input-group"]} input-group`}>
								<div className={`${classes["input-group-append"]} `}>
									<img id="arrow-r" src="/img/arrow-right.png" alt="image" />
								</div>

								<input
									type="text"
									className="form-control"
									{...register("email")}
									placeholder={`${
										loading ? "Thank you for subscribing" : "Enter Email"
									}`}
									disabled={loading}
								/>
								<button
									type="submit"
									className={classes["count-me-in"]}
									disabled={loading}
								>
									<img src="/img/plane-orange-icon1.png" alt="image" />
								</button>
							</div>
						</div>

						<div
							className={`${classes.inputHubSpotLoading} ${
								loading ? classes.inputHubSpotLoadingActive : ""
							}`}
						>
							<div className={classes.inputHubSpotText}>
								Thank you for subscribing
							</div>
						</div>
					</div>
				</form>
			</div>

			{/* {loading && (
				<div className={styles.statesWindowsConteiner}>
					<div className={styles.statesWindowsModal}>
						<div className={styles.boxmodal}>
							<div className={styles.titlemodal}>
								Thank you, please check your email for confirmation.
							</div>
							<div
								className={styles.closemodal}
								onClick={() => {
									setLoading(false);
								}}
							>
								<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
							</div>
						</div>
					</div>
				</div>
			)} */}
		</section>
	);
};
export default NewsLetterSubscribe;
